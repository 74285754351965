export const AppBreadcrumbs = () => import('../../components/AppBreadcrumbs.vue' /* webpackChunkName: "components/app-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppCookies = () => import('../../components/AppCookies.vue' /* webpackChunkName: "components/app-cookies" */).then(c => wrapFunctional(c.default || c))
export const AppFaq = () => import('../../components/AppFaq.vue' /* webpackChunkName: "components/app-faq" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppLeftSidebar = () => import('../../components/AppLeftSidebar.vue' /* webpackChunkName: "components/app-left-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AppLogo = () => import('../../components/AppLogo.vue' /* webpackChunkName: "components/app-logo" */).then(c => wrapFunctional(c.default || c))
export const AppTopbar = () => import('../../components/AppTopbar.vue' /* webpackChunkName: "components/app-topbar" */).then(c => wrapFunctional(c.default || c))
export const AppUpButton = () => import('../../components/AppUpButton.vue' /* webpackChunkName: "components/app-up-button" */).then(c => wrapFunctional(c.default || c))
export const HtmlContentBlock = () => import('../../components/HtmlContentBlock.vue' /* webpackChunkName: "components/html-content-block" */).then(c => wrapFunctional(c.default || c))
export const InfoModal = () => import('../../components/InfoModal.vue' /* webpackChunkName: "components/info-modal" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const PageBlocks = () => import('../../components/PageBlocks.vue' /* webpackChunkName: "components/page-blocks" */).then(c => wrapFunctional(c.default || c))
export const PagePreloader = () => import('../../components/PagePreloader.vue' /* webpackChunkName: "components/page-preloader" */).then(c => wrapFunctional(c.default || c))
export const RichVideo = () => import('../../components/RichVideo.vue' /* webpackChunkName: "components/rich-video" */).then(c => wrapFunctional(c.default || c))
export const ServiceCenters = () => import('../../components/ServiceCenters.vue' /* webpackChunkName: "components/service-centers" */).then(c => wrapFunctional(c.default || c))
export const ServiceCentersLayout = () => import('../../components/ServiceCentersLayout.vue' /* webpackChunkName: "components/service-centers-layout" */).then(c => wrapFunctional(c.default || c))
export const ServiceCentersList = () => import('../../components/ServiceCentersList.vue' /* webpackChunkName: "components/service-centers-list" */).then(c => wrapFunctional(c.default || c))
export const ServiceCentersListItem = () => import('../../components/ServiceCentersListItem.vue' /* webpackChunkName: "components/service-centers-list-item" */).then(c => wrapFunctional(c.default || c))
export const SocialsList = () => import('../../components/SocialsList.vue' /* webpackChunkName: "components/socials-list" */).then(c => wrapFunctional(c.default || c))
export const SubscribeNewsletterBlock = () => import('../../components/SubscribeNewsletterBlock.vue' /* webpackChunkName: "components/subscribe-newsletter-block" */).then(c => wrapFunctional(c.default || c))
export const TopbarLanguage = () => import('../../components/TopbarLanguage.vue' /* webpackChunkName: "components/topbar-language" */).then(c => wrapFunctional(c.default || c))
export const TopbarLocation = () => import('../../components/TopbarLocation.vue' /* webpackChunkName: "components/topbar-location" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerModal = () => import('../../components/VideoPlayerModal.vue' /* webpackChunkName: "components/video-player-modal" */).then(c => wrapFunctional(c.default || c))
export const AppSearch = () => import('../../components/AppSearch/AppSearch.vue' /* webpackChunkName: "components/app-search" */).then(c => wrapFunctional(c.default || c))
export const AppSearchNoResults = () => import('../../components/AppSearch/AppSearchNoResults.vue' /* webpackChunkName: "components/app-search-no-results" */).then(c => wrapFunctional(c.default || c))
export const AppSearchResultDocuments = () => import('../../components/AppSearch/AppSearchResultDocuments.vue' /* webpackChunkName: "components/app-search-result-documents" */).then(c => wrapFunctional(c.default || c))
export const AppSearchResultDocumentsItem = () => import('../../components/AppSearch/AppSearchResultDocumentsItem.vue' /* webpackChunkName: "components/app-search-result-documents-item" */).then(c => wrapFunctional(c.default || c))
export const AppSearchResultNews = () => import('../../components/AppSearch/AppSearchResultNews.vue' /* webpackChunkName: "components/app-search-result-news" */).then(c => wrapFunctional(c.default || c))
export const AppSearchResultProducts = () => import('../../components/AppSearch/AppSearchResultProducts.vue' /* webpackChunkName: "components/app-search-result-products" */).then(c => wrapFunctional(c.default || c))
export const UiIconArrowRight = () => import('../../components/icons/UiIconArrowRight.vue' /* webpackChunkName: "components/ui-icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const UiIconArrowRightBold = () => import('../../components/icons/UiIconArrowRightBold.vue' /* webpackChunkName: "components/ui-icon-arrow-right-bold" */).then(c => wrapFunctional(c.default || c))
export const UiIconArrowUp = () => import('../../components/icons/UiIconArrowUp.vue' /* webpackChunkName: "components/ui-icon-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const UiIconCheckmark = () => import('../../components/icons/UiIconCheckmark.vue' /* webpackChunkName: "components/ui-icon-checkmark" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronDown = () => import('../../components/icons/UiIconChevronDown.vue' /* webpackChunkName: "components/ui-icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronDownBig = () => import('../../components/icons/UiIconChevronDownBig.vue' /* webpackChunkName: "components/ui-icon-chevron-down-big" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronLeft = () => import('../../components/icons/UiIconChevronLeft.vue' /* webpackChunkName: "components/ui-icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronRight = () => import('../../components/icons/UiIconChevronRight.vue' /* webpackChunkName: "components/ui-icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronRightThin = () => import('../../components/icons/UiIconChevronRightThin.vue' /* webpackChunkName: "components/ui-icon-chevron-right-thin" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronUp = () => import('../../components/icons/UiIconChevronUp.vue' /* webpackChunkName: "components/ui-icon-chevron-up" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronUpBig = () => import('../../components/icons/UiIconChevronUpBig.vue' /* webpackChunkName: "components/ui-icon-chevron-up-big" */).then(c => wrapFunctional(c.default || c))
export const UiIconClose = () => import('../../components/icons/UiIconClose.vue' /* webpackChunkName: "components/ui-icon-close" */).then(c => wrapFunctional(c.default || c))
export const UiIconCloseBold = () => import('../../components/icons/UiIconCloseBold.vue' /* webpackChunkName: "components/ui-icon-close-bold" */).then(c => wrapFunctional(c.default || c))
export const UiIconCopy = () => import('../../components/icons/UiIconCopy.vue' /* webpackChunkName: "components/ui-icon-copy" */).then(c => wrapFunctional(c.default || c))
export const UiIconDownload = () => import('../../components/icons/UiIconDownload.vue' /* webpackChunkName: "components/ui-icon-download" */).then(c => wrapFunctional(c.default || c))
export const UiIconFacebook = () => import('../../components/icons/UiIconFacebook.vue' /* webpackChunkName: "components/ui-icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const UiIconFilter = () => import('../../components/icons/UiIconFilter.vue' /* webpackChunkName: "components/ui-icon-filter" */).then(c => wrapFunctional(c.default || c))
export const UiIconInstagram = () => import('../../components/icons/UiIconInstagram.vue' /* webpackChunkName: "components/ui-icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const UiIconLinkedIn = () => import('../../components/icons/UiIconLinkedIn.vue' /* webpackChunkName: "components/ui-icon-linked-in" */).then(c => wrapFunctional(c.default || c))
export const UiIconLocation = () => import('../../components/icons/UiIconLocation.vue' /* webpackChunkName: "components/ui-icon-location" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoMain = () => import('../../components/icons/UiIconLogoMain.vue' /* webpackChunkName: "components/ui-icon-logo-main" */).then(c => wrapFunctional(c.default || c))
export const UiIconMenuBar = () => import('../../components/icons/UiIconMenuBar.vue' /* webpackChunkName: "components/ui-icon-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const UiIconPdfFile = () => import('../../components/icons/UiIconPdfFile.vue' /* webpackChunkName: "components/ui-icon-pdf-file" */).then(c => wrapFunctional(c.default || c))
export const UiIconPlay = () => import('../../components/icons/UiIconPlay.vue' /* webpackChunkName: "components/ui-icon-play" */).then(c => wrapFunctional(c.default || c))
export const UiIconQuote = () => import('../../components/icons/UiIconQuote.vue' /* webpackChunkName: "components/ui-icon-quote" */).then(c => wrapFunctional(c.default || c))
export const UiIconSearch = () => import('../../components/icons/UiIconSearch.vue' /* webpackChunkName: "components/ui-icon-search" */).then(c => wrapFunctional(c.default || c))
export const UiIconSliderArrowLeft = () => import('../../components/icons/UiIconSliderArrowLeft.vue' /* webpackChunkName: "components/ui-icon-slider-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const UiIconSliderArrowRight = () => import('../../components/icons/UiIconSliderArrowRight.vue' /* webpackChunkName: "components/ui-icon-slider-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const UiIconTelegram = () => import('../../components/icons/UiIconTelegram.vue' /* webpackChunkName: "components/ui-icon-telegram" */).then(c => wrapFunctional(c.default || c))
export const UiIconTwitter = () => import('../../components/icons/UiIconTwitter.vue' /* webpackChunkName: "components/ui-icon-twitter" */).then(c => wrapFunctional(c.default || c))
export const UiIconUser = () => import('../../components/icons/UiIconUser.vue' /* webpackChunkName: "components/ui-icon-user" */).then(c => wrapFunctional(c.default || c))
export const UiIconWarning = () => import('../../components/icons/UiIconWarning.vue' /* webpackChunkName: "components/ui-icon-warning" */).then(c => wrapFunctional(c.default || c))
export const UiIconYoutube = () => import('../../components/icons/UiIconYoutube.vue' /* webpackChunkName: "components/ui-icon-youtube" */).then(c => wrapFunctional(c.default || c))
export const UiAccordeon = () => import('../../components/ui/UiAccordeon.vue' /* webpackChunkName: "components/ui-accordeon" */).then(c => wrapFunctional(c.default || c))
export const UiAutocomplete = () => import('../../components/ui/UiAutocomplete.vue' /* webpackChunkName: "components/ui-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const UiButton = () => import('../../components/ui/UiButton.vue' /* webpackChunkName: "components/ui-button" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/ui/UiCheckbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiDisclosure = () => import('../../components/ui/UiDisclosure.vue' /* webpackChunkName: "components/ui-disclosure" */).then(c => wrapFunctional(c.default || c))
export const UiDisclosureButton = () => import('../../components/ui/UiDisclosureButton.vue' /* webpackChunkName: "components/ui-disclosure-button" */).then(c => wrapFunctional(c.default || c))
export const UiDisclosurePanel = () => import('../../components/ui/UiDisclosurePanel.vue' /* webpackChunkName: "components/ui-disclosure-panel" */).then(c => wrapFunctional(c.default || c))
export const UiDrawer = () => import('../../components/ui/UiDrawer.vue' /* webpackChunkName: "components/ui-drawer" */).then(c => wrapFunctional(c.default || c))
export const UiIcon = () => import('../../components/ui/UiIcon.vue' /* webpackChunkName: "components/ui-icon" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/ui/UiInput.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiLink = () => import('../../components/ui/UiLink.vue' /* webpackChunkName: "components/ui-link" */).then(c => wrapFunctional(c.default || c))
export const UiMap = () => import('../../components/ui/UiMap.vue' /* webpackChunkName: "components/ui-map" */).then(c => wrapFunctional(c.default || c))
export const UiMarkerPoint = () => import('../../components/ui/UiMarkerPoint.vue' /* webpackChunkName: "components/ui-marker-point" */).then(c => wrapFunctional(c.default || c))
export const UiModal = () => import('../../components/ui/UiModal.vue' /* webpackChunkName: "components/ui-modal" */).then(c => wrapFunctional(c.default || c))
export const UiPackery = () => import('../../components/ui/UiPackery.vue' /* webpackChunkName: "components/ui-packery" */).then(c => wrapFunctional(c.default || c))
export const UiPackeryItem = () => import('../../components/ui/UiPackeryItem.vue' /* webpackChunkName: "components/ui-packery-item" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/UiPagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiPopup = () => import('../../components/ui/UiPopup.vue' /* webpackChunkName: "components/ui-popup" */).then(c => wrapFunctional(c.default || c))
export const UiSearchForm = () => import('../../components/ui/UiSearchForm.vue' /* webpackChunkName: "components/ui-search-form" */).then(c => wrapFunctional(c.default || c))
export const UiSearchFormPopularQueries = () => import('../../components/ui/UiSearchFormPopularQueries.vue' /* webpackChunkName: "components/ui-search-form-popular-queries" */).then(c => wrapFunctional(c.default || c))
export const UiSearchFormResults = () => import('../../components/ui/UiSearchFormResults.vue' /* webpackChunkName: "components/ui-search-form-results" */).then(c => wrapFunctional(c.default || c))
export const UiSectionTitle = () => import('../../components/ui/UiSectionTitle.vue' /* webpackChunkName: "components/ui-section-title" */).then(c => wrapFunctional(c.default || c))
export const UiSelect = () => import('../../components/ui/UiSelect.vue' /* webpackChunkName: "components/ui-select" */).then(c => wrapFunctional(c.default || c))
export const UiSelectOption = () => import('../../components/ui/UiSelectOption.vue' /* webpackChunkName: "components/ui-select-option" */).then(c => wrapFunctional(c.default || c))
export const UiSpinner = () => import('../../components/ui/UiSpinner.vue' /* webpackChunkName: "components/ui-spinner" */).then(c => wrapFunctional(c.default || c))
export const UiSpinnerCircle = () => import('../../components/ui/UiSpinnerCircle.vue' /* webpackChunkName: "components/ui-spinner-circle" */).then(c => wrapFunctional(c.default || c))
export const UiTab = () => import('../../components/ui/UiTab.vue' /* webpackChunkName: "components/ui-tab" */).then(c => wrapFunctional(c.default || c))
export const UiTabs = () => import('../../components/ui/UiTabs.vue' /* webpackChunkName: "components/ui-tabs" */).then(c => wrapFunctional(c.default || c))
export const UiTabsContent = () => import('../../components/ui/UiTabsContent.vue' /* webpackChunkName: "components/ui-tabs-content" */).then(c => wrapFunctional(c.default || c))
export const UiTag = () => import('../../components/ui/UiTag.vue' /* webpackChunkName: "components/ui-tag" */).then(c => wrapFunctional(c.default || c))
export const UiTextarea = () => import('../../components/ui/UiTextarea.vue' /* webpackChunkName: "components/ui-textarea" */).then(c => wrapFunctional(c.default || c))
export const UiVideo = () => import('../../components/ui/UiVideo.vue' /* webpackChunkName: "components/ui-video" */).then(c => wrapFunctional(c.default || c))
export const CtaSectionBanner = () => import('../../components/sections/ctaSection/CtaSectionBanner.vue' /* webpackChunkName: "components/cta-section-banner" */).then(c => wrapFunctional(c.default || c))
export const CtaSectionWithBackground = () => import('../../components/sections/ctaSection/CtaSectionWithBackground.vue' /* webpackChunkName: "components/cta-section-with-background" */).then(c => wrapFunctional(c.default || c))
export const ConsultationForm = () => import('../../components/sections/formSection/ConsultationForm.vue' /* webpackChunkName: "components/consultation-form" */).then(c => wrapFunctional(c.default || c))
export const ServiceForm = () => import('../../components/sections/formSection/ServiceForm.vue' /* webpackChunkName: "components/service-form" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategories = () => import('../../components/sections/categorySection/CatalogCategories.vue' /* webpackChunkName: "components/catalog-categories" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoryItem = () => import('../../components/sections/categorySection/CatalogCategoryItem.vue' /* webpackChunkName: "components/catalog-category-item" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoryItemImages = () => import('../../components/sections/categorySection/CatalogCategoryItemImages.vue' /* webpackChunkName: "components/catalog-category-item-images" */).then(c => wrapFunctional(c.default || c))
export const CategoriesSection = () => import('../../components/sections/categorySection/CategoriesSection.vue' /* webpackChunkName: "components/categories-section" */).then(c => wrapFunctional(c.default || c))
export const CategoryAccessories = () => import('../../components/sections/categorySection/CategoryAccessories.vue' /* webpackChunkName: "components/category-accessories" */).then(c => wrapFunctional(c.default || c))
export const CategoryApplications = () => import('../../components/sections/categorySection/CategoryApplications.vue' /* webpackChunkName: "components/category-applications" */).then(c => wrapFunctional(c.default || c))
export const CategoryBanner = () => import('../../components/sections/categorySection/CategoryBanner.vue' /* webpackChunkName: "components/category-banner" */).then(c => wrapFunctional(c.default || c))
export const CategoryBannerV2 = () => import('../../components/sections/categorySection/CategoryBannerV2.vue' /* webpackChunkName: "components/category-banner-v2" */).then(c => wrapFunctional(c.default || c))
export const CategoryFaq = () => import('../../components/sections/categorySection/CategoryFaq.vue' /* webpackChunkName: "components/category-faq" */).then(c => wrapFunctional(c.default || c))
export const CategoryFeatures = () => import('../../components/sections/categorySection/CategoryFeatures.vue' /* webpackChunkName: "components/category-features" */).then(c => wrapFunctional(c.default || c))
export const CategoryFeaturesBlocks = () => import('../../components/sections/categorySection/CategoryFeaturesBlocks.vue' /* webpackChunkName: "components/category-features-blocks" */).then(c => wrapFunctional(c.default || c))
export const CategoryFeaturesBlocksMobile = () => import('../../components/sections/categorySection/CategoryFeaturesBlocksMobile.vue' /* webpackChunkName: "components/category-features-blocks-mobile" */).then(c => wrapFunctional(c.default || c))
export const CategoryInfoBlocks = () => import('../../components/sections/categorySection/CategoryInfoBlocks.vue' /* webpackChunkName: "components/category-info-blocks" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductSeries = () => import('../../components/sections/categorySection/CategoryProductSeries.vue' /* webpackChunkName: "components/category-product-series" */).then(c => wrapFunctional(c.default || c))
export const CategoryRecommended = () => import('../../components/sections/categorySection/CategoryRecommended.vue' /* webpackChunkName: "components/category-recommended" */).then(c => wrapFunctional(c.default || c))
export const CategoryRecommendedProducts = () => import('../../components/sections/categorySection/CategoryRecommendedProducts.vue' /* webpackChunkName: "components/category-recommended-products" */).then(c => wrapFunctional(c.default || c))
export const PartnersSection = () => import('../../components/sections/categorySection/PartnersSection.vue' /* webpackChunkName: "components/partners-section" */).then(c => wrapFunctional(c.default || c))
export const SoftwareCategoryAwards = () => import('../../components/sections/categorySection/SoftwareCategoryAwards.vue' /* webpackChunkName: "components/software-category-awards" */).then(c => wrapFunctional(c.default || c))
export const SoftwareCategoryChoosePlan = () => import('../../components/sections/categorySection/SoftwareCategoryChoosePlan.vue' /* webpackChunkName: "components/software-category-choose-plan" */).then(c => wrapFunctional(c.default || c))
export const SoftwareCategoryFirstScreen = () => import('../../components/sections/categorySection/SoftwareCategoryFirstScreen.vue' /* webpackChunkName: "components/software-category-first-screen" */).then(c => wrapFunctional(c.default || c))
export const SoftwareCategoryInfoblocks = () => import('../../components/sections/categorySection/SoftwareCategoryInfoblocks.vue' /* webpackChunkName: "components/software-category-infoblocks" */).then(c => wrapFunctional(c.default || c))
export const SoftwareCategoryReviewsSlider = () => import('../../components/sections/categorySection/SoftwareCategoryReviewsSlider.vue' /* webpackChunkName: "components/software-category-reviews-slider" */).then(c => wrapFunctional(c.default || c))
export const SoftwareCategoryTryDemo = () => import('../../components/sections/categorySection/SoftwareCategoryTryDemo.vue' /* webpackChunkName: "components/software-category-try-demo" */).then(c => wrapFunctional(c.default || c))
export const SoftwareCategoryTryDemoForm = () => import('../../components/sections/categorySection/SoftwareCategoryTryDemoForm.vue' /* webpackChunkName: "components/software-category-try-demo-form" */).then(c => wrapFunctional(c.default || c))
export const SoftwareCategoryTryDemoFormModal = () => import('../../components/sections/categorySection/SoftwareCategoryTryDemoFormModal.vue' /* webpackChunkName: "components/software-category-try-demo-form-modal" */).then(c => wrapFunctional(c.default || c))
export const SoftwareCategoryUserHelp = () => import('../../components/sections/categorySection/SoftwareCategoryUserHelp.vue' /* webpackChunkName: "components/software-category-user-help" */).then(c => wrapFunctional(c.default || c))
export const LatestNews = () => import('../../components/sections/newsSection/LatestNews.vue' /* webpackChunkName: "components/latest-news" */).then(c => wrapFunctional(c.default || c))
export const NewsItem = () => import('../../components/sections/newsSection/NewsItem.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c))
export const ProductBenefits = () => import('../../components/sections/productSection/ProductBenefits.vue' /* webpackChunkName: "components/product-benefits" */).then(c => wrapFunctional(c.default || c))
export const ProductBenefitsItem = () => import('../../components/sections/productSection/ProductBenefitsItem.vue' /* webpackChunkName: "components/product-benefits-item" */).then(c => wrapFunctional(c.default || c))
export const ProductCertificates = () => import('../../components/sections/productSection/ProductCertificates.vue' /* webpackChunkName: "components/product-certificates" */).then(c => wrapFunctional(c.default || c))
export const ProductContentBanner = () => import('../../components/sections/productSection/ProductContentBanner.vue' /* webpackChunkName: "components/product-content-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductContentBannerPoints = () => import('../../components/sections/productSection/ProductContentBannerPoints.vue' /* webpackChunkName: "components/product-content-banner-points" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/sections/productSection/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductFeatures = () => import('../../components/sections/productSection/ProductFeatures.vue' /* webpackChunkName: "components/product-features" */).then(c => wrapFunctional(c.default || c))
export const ProductFeaturesBanner = () => import('../../components/sections/productSection/ProductFeaturesBanner.vue' /* webpackChunkName: "components/product-features-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductFootnote = () => import('../../components/sections/productSection/ProductFootnote.vue' /* webpackChunkName: "components/product-footnote" */).then(c => wrapFunctional(c.default || c))
export const ProductSeries = () => import('../../components/sections/productSection/ProductSeries.vue' /* webpackChunkName: "components/product-series" */).then(c => wrapFunctional(c.default || c))
export const ProductSeriesGallery = () => import('../../components/sections/productSection/ProductSeriesGallery.vue' /* webpackChunkName: "components/product-series-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductSeriesHeader = () => import('../../components/sections/productSection/ProductSeriesHeader.vue' /* webpackChunkName: "components/product-series-header" */).then(c => wrapFunctional(c.default || c))
export const ProductSoftware = () => import('../../components/sections/productSection/ProductSoftware.vue' /* webpackChunkName: "components/product-software" */).then(c => wrapFunctional(c.default || c))
export const ProductUserHelp = () => import('../../components/sections/productSection/ProductUserHelp.vue' /* webpackChunkName: "components/product-user-help" */).then(c => wrapFunctional(c.default || c))
export const ProductUserHelpDownloads = () => import('../../components/sections/productSection/ProductUserHelpDownloads.vue' /* webpackChunkName: "components/product-user-help-downloads" */).then(c => wrapFunctional(c.default || c))
export const ProductUserHelpVideos = () => import('../../components/sections/productSection/ProductUserHelpVideos.vue' /* webpackChunkName: "components/product-user-help-videos" */).then(c => wrapFunctional(c.default || c))
export const ProductVideo = () => import('../../components/sections/productSection/ProductVideo.vue' /* webpackChunkName: "components/product-video" */).then(c => wrapFunctional(c.default || c))
export const ProductWarrantyBanner = () => import('../../components/sections/productSection/ProductWarrantyBanner.vue' /* webpackChunkName: "components/product-warranty-banner" */).then(c => wrapFunctional(c.default || c))
export const RelatedProducts = () => import('../../components/sections/productSection/RelatedProducts.vue' /* webpackChunkName: "components/related-products" */).then(c => wrapFunctional(c.default || c))
export const ServiceCentersSection = () => import('../../components/sections/serviceCentersSection/ServiceCentersSection.vue' /* webpackChunkName: "components/service-centers-section" */).then(c => wrapFunctional(c.default || c))
export const BannersSlider = () => import('../../components/sections/sliderSection/BannersSlider.vue' /* webpackChunkName: "components/banners-slider" */).then(c => wrapFunctional(c.default || c))
export const SolutionsCarousel = () => import('../../components/sections/sliderSection/SolutionsCarousel.vue' /* webpackChunkName: "components/solutions-carousel" */).then(c => wrapFunctional(c.default || c))
export const SolutionsCarouselSlide = () => import('../../components/sections/sliderSection/SolutionsCarouselSlide.vue' /* webpackChunkName: "components/solutions-carousel-slide" */).then(c => wrapFunctional(c.default || c))
export const SolutionsCarouselSlideMobile = () => import('../../components/sections/sliderSection/SolutionsCarouselSlideMobile.vue' /* webpackChunkName: "components/solutions-carousel-slide-mobile" */).then(c => wrapFunctional(c.default || c))
export const SolutionsCarouselSlider = () => import('../../components/sections/sliderSection/SolutionsCarouselSlider.vue' /* webpackChunkName: "components/solutions-carousel-slider" */).then(c => wrapFunctional(c.default || c))
export const SolutionsCarouselSliderMobile = () => import('../../components/sections/sliderSection/SolutionsCarouselSliderMobile.vue' /* webpackChunkName: "components/solutions-carousel-slider-mobile" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
