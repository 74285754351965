
import {
  computed,
  defineComponent,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import HeaderMainMenu from '@/domains/menus/components/HeaderMainMenu.vue';
import HeaderMainMenuMobile from '@/domains/menus/components/HeaderMainMenuMobile.vue';
import useOpenClose from '@/composables/useOpenClose';
import useBreakpoint from '@/composables/useBreakpoint';

export default defineComponent({
  components: {
    HeaderMainMenu,
    HeaderMainMenuMobile,
  },
  props: {
    menuTop: {
      type: Array,
      default: () => [],
    },
    menuMain: {
      type: Array,
      default: () => [],
    },
    dark: {
      type: Boolean,
      default: undefined,
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');
    const mainMenuWrap = ref<HTMLElement>();
    const {
      $config: { joinuApp: isJoinuApp },
    } = useContext();

    const {
      isOpen: isOpenSearch,
      open: openSearch,
      close: closeSearch,
    } = useOpenClose();
    const isMenuOpen = ref(false);
    const isMobileMenuOpen = ref(false);

    const headerBgColor = computed(() => {
      if (isMenuOpen.value || isJoinuApp) return 'md:bg-gray-lighten3';
      if (props.dark) return 'md:bg-gray-lighten3';

      return 'md:bg-transparent';
    });

    const headerTextColor = computed(() => {
      if (isMenuOpen.value || isJoinuApp) return 'md:text-[#494949]';
      if (props.dark) return 'md:text-slate';

      return 'md:text-white';
    });

    const toggleMenu = (value: boolean) => {
      isMenuOpen.value = value;
    };

    const toggleMobileMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    };

    return {
      isDesktop,
      headerBgColor,
      headerTextColor,
      mainMenuWrap,
      isMenuOpen,
      isMobileMenuOpen,
      isOpenSearch,
      openSearch,
      closeSearch,
      toggleMenu,
      toggleMobileMenu,
      isJoinuApp,
    };
  },
});
